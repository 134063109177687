<template>
    <div>
        <h3 class="tw-text-xs tw-uppercase tw-text-blue-700">
            {{ $t('productProfile.productInfo') }}
        </h3>
        <form class="form-horizontal tw-space-y-4">
            <div class="row" v-if="enableAccount">
                <label
                    class="col-md-4 form-col-label control-label required tw-text-right"
                    >{{ $t('productProfile.account') }}</label
                >
                <div class="col-md-8">
                    <Select
                        v-model.number="model.account_id"
                        :filter-by-label="true"
                        :placeholder="$t('select')"
                        :clearable="true"
                        filterable
                        @on-query-change="handleSearch"
                    >
                        <Option
                            v-for="item in accounts"
                            :value="item.account_id"
                            :key="item.account_id"
                            :label="item.account_name_en"
                        >
                            {{
                                item.account_number + '-' + item.account_name_en
                            }}
                        </Option>
                    </Select>
                    <div class="validate" v-if="errors.has('account_id')">
                        {{ errors.first('account_id') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <label
                    class="col-md-4 form-col-label control-label required tw-text-right"
                    >{{ $t('productProfile.category') }}</label
                >
                <div class="col-md-8">
                    <Select
                        v-model="model.category_id"
                        :filter-by-label="true"
                        :placeholder="$t('select')"
                        :clearable="true"
                        @on-change="getBarcode"
                        :disabled="is_disabled"
                    >
                        <Option
                            v-for="cat in productCategory"
                            :value="cat.category_id"
                            :key="cat.category_id"
                        >
                            {{ cat.category_name_en }}
                        </Option>
                    </Select>
                    <div class="validate" v-if="errors.has('category_id')">
                        {{ errors.first('category_id') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <label
                    class="col-md-4 form-col-label control-label required tw-text-right"
                    >{{ $t('productProfile.group') }}</label
                >
                <div class="col-md-8">
                    <Select
                        v-model="model.pro_group_id"
                        :filter-by-label="true"
                        :placeholder="$t('select')"
                        :clearable="true"
                    >
                        <Option
                            v-for="group in groupList"
                            :value="group.pro_group_id"
                            :key="group.pro_group_id"
                        >
                            {{ group.pro_group_name_en }}
                        </Option>
                    </Select>
                    <!-- <a-select
                        class="tw-w-full"
                        v-model.number="model.pro_group_id"
                        :options="groupList"
                        showSearch
                        :filterOption="
                            (input, option) =>
                                option.componentOptions.children[0].text
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                        "
                    ></a-select> -->
                    <div class="validate" v-if="errors.has('pro_group_id')">
                        {{ errors.first('pro_group_id') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <label
                    class="col-md-4 form-col-label control-label tw-text-right"
                    >{{ $t('productProfile.nameKh') }}</label
                >
                <div class="col-md-8">
                    <input
                        v-model.trim="model.product_name_kh"
                        type="text"
                        class="form-control"
                    />
                    <div class="validate" v-if="errors.has('product_name_kh')">
                        {{ errors.first('product_name_kh') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <label
                    class="col-md-4 form-col-label control-label required tw-text-right"
                    >{{ $t('productProfile.nameEn') }}</label
                >
                <div class="col-md-8">
                    <input
                        v-model.trim="model.product_name_en"
                        type="text"
                        class="form-control"
                    />
                    <div class="validate" v-if="errors.has('product_name_en')">
                        {{ errors.first('product_name_en') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <label
                    class="col-md-4 form-col-label control-label required tw-text-right"
                    >{{ $t('productProfile.mode') }}</label
                >
                <div class="col-md-8">
                    <Select
                        v-model="model.product_mode_id"
                        :filter-by-label="true"
                        :placeholder="$t('select')"
                        :clearable="true"
                    >
                        <Option
                            v-for="mode in productMode"
                            :value="mode.product_mode_id"
                            :key="mode.product_mode_id"
                        >
                            {{ mode.product_mode }}
                        </Option>
                    </Select>
                    <div class="validate" v-if="errors.has('product_mode_id')">
                        {{ errors.first('product_mode_id') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <label
                    class="col-md-4 form-col-label control-label required tw-text-right"
                    >{{ $t('productProfile.productType') }}</label
                >
                <div class="col-md-8">
                    <Select
                        v-model="model.product_type_id"
                        :filter-by-label="true"
                        :placeholder="$t('select')"
                        :clearable="true"
                        @on-change="fetchRecipe"
                    >
                        <Option
                            v-for="type in productType"
                            :value="type.product_type_id"
                            :key="type.product_type_id"
                        >
                            {{ type.product_type_en }}
                        </Option>
                    </Select>

                    <!-- <a-select
                        v-model.number="model.product_type_id"
                        :options="productType"
                        class="tw-w-full"
                        @change="fetchRecipe"
                        showSearch
                        :filterOption="
                            (input, option) =>
                                option.componentOptions.children[0].text
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                        "
                    ></a-select> -->
                    <div class="validate" v-if="errors.has('product_type_id')">
                        {{ errors.first('product_type_id') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <label
                    class="col-md-4 form-col-label control-label required tw-text-right"
                    >{{ $t('productProfile.barcode') }}</label
                >
                <div class="col-md-8">
                    <input
                        v-model.trim="model.barcode"
                        type="text"
                        class="form-control"
                    />
                    <div class="validate" v-if="errors.has('barcode')">
                        {{ errors.first('barcode') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <label
                    class="col-md-4 form-col-label control-label required tw-text-right"
                    >{{ $t('productProfile.uomCategory') }}</label
                >
                <div class="col-md-8">
                    <Select
                        v-model="model.uom_cat_id"
                        :filter-by-label="true"
                        :placeholder="$t('select')"
                        :clearable="true"
                        @on-change="getSalecode"
                        :disabled="notAllowChange || is_disabled"
                    >
                        <Option
                            v-for="uom in uomCategory"
                            :value="uom.uom_cat_id"
                            :key="uom.uom_cat_id"
                        >
                            {{ uom.uom_cat_name_en }}
                        </Option>
                    </Select>

                    <!-- <a-select
                        class="tw-w-full"
                        v-model.number="model.uom_cat_id"
                        :options="uomCategory"
                        :disabled="notAllowChange || is_disabled"
                        @change="getSalecode"
                        showSearch
                        :filterOption="
                            (input, option) =>
                                option.componentOptions.children[0].text
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                        "
                    ></a-select> -->
                    <div class="validate" v-if="errors.has('uom_cat_id')">
                        {{ errors.first('uom_cat_id') }}
                    </div>
                </div>
            </div>
            <!-- <div class="row">
        <label class="col-md-4 form-col-label control-label required">{{$t('productProfile.ageEligible')}}</label>
        <div class="col-md-8 ts-space-x-4 ts-flex">
          <input
						v-model.number="model.age_eligible_from" 
						type="number" class="ts-w-1/2 ts-border ts-border-gray-400 ts-rounded-md ts-px-3 ts-py-2 ts-outline-none"
						:class="{ 'ts-border-red-600': errors.has('age_eligible_from') }"
						:placeholder="$t('productProfile.from')"
					>
          <input 
						v-model.number="model.age_eligible_to"
						type="number" 
						class="ts-w-1/2 ts-border ts-border-gray-400 ts-rounded-md ts-px-3 ts-py-2 ts-outline-none"
						:class="{ 'ts-border-red-600': errors.has('age_eligible_to') }"
						:placeholder="$t('productProfile.to')"
					>
        </div>
      </div> -->
            <div class="row">
                <label
                    class="col-md-4 form-col-label control-label tw-text-right"
                    >{{ $t('productProfile.orderAllow') }}</label
                >
                <div class="col-md-8 tw-space-x-4 tw-flex">
                    <input
                        v-model.number="model.minimum_reorder_qty"
                        type="number"
                        class="form-control"
                        :class="{
                            'ts-border-red-600': errors.has(
                                'minimum_reorder_qty'
                            )
                        }"
                        :placeholder="$t('productProfile.minimum')"
                    />
                    <input
                        v-model.number="model.maximum_reorder_allow"
                        type="number"
                        class="form-control"
                        :class="{
                            'tw-border-red-600': errors.has(
                                'maximum_reorder_allow'
                            )
                        }"
                        :placeholder="$t('productProfile.maximum')"
                    />
                </div>
            </div>
            <div class="row">
                <label class="col-md-4 form-col-label control-label"></label>
                <div class="col-md-8 tw-flex tw-space-x-3">
                    <ts-checkbox v-model="model.is_expirable">
                        {{ $t('productProfile.isExpirable') }}
                    </ts-checkbox>
                    <ts-checkbox v-model="model.is_feature">
                        {{ $t('productProfile.isFeature') }}
                    </ts-checkbox>
                </div>
            </div>
            <div class="row">
                <label class="col-md-4 form-col-label control-label"></label>
                <div class="col-md-8 tw-flex tw-space-x-3">
                    <ts-checkbox v-model="model.vat_eligible">
                        {{ $t('productProfile.vatEligible') }}
                    </ts-checkbox>
                    <ts-checkbox v-model="model.is_generate_unique_serial">
                        {{ $t('productProfile.generateUniqueSerial') }}
                    </ts-checkbox>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'
import { mapActions, mapState } from 'vuex'
import { isEmpty, debounce } from 'lodash'

export default {
    name: 'productInfo',
    props: ['value', 'validate'],
    data () {
        return {
            errors: new Errors(),
            model: {},
            is_disabled: false,
            searching: false,
            accounts: []
        }
    },
    created () {
        this.model = this.value
    },
    computed: {
        ...mapState('product/profile', ['formViewDatas']),
        enableAccount () {
            return this.$store.state.product.profile.setting.is_accounting
        },
        isEdit () {
            return !isEmpty(this.$store.state.product.profile.edit_data)
        },
        productType () {
            return this.formViewDatas.productType
        },
        productCategory () {
            return this.formViewDatas.category
        },
        productMode () {
            return this.formViewDatas.mode
        },
        uomCategory () {
            return this.formViewDatas.uomCategory
        },
        groupList () {
            if (!this.model.category_id) {
                return this.formViewDatas.group
            }
            let groups = this.formViewDatas.group
            return groups.filter(g => g.category_id == this.model.category_id)
        },
        notAllowChange () {
            return this.model.barcode == null || this.model.barcode == ''
        },
        notForWholesale () {
            return !this.$store.state.authUser.sys_setting.is_whole_sale
        }
    },
    methods: {
        ...mapActions('product/profile', [
            'barcode',
            'salecode',
            'recipe',
            'allowChangUomCat',
            'formViewModel'
        ]),
        getBarcode (value) {
            this.$emit('loading', true)
            this.model.pro_group_id = null
            this.model.product_sale_code = []
            this.model.uom_cat_id = null
            this.barcode(value)
                .then(response => {
                    this.model.barcode = response.data
                })
                .finally(() => {
                    this.$emit('loading', false)
                })
        },
        getSalecode () {
            this.$emit('loading', true)
            if (!this.model.product_id) {
                this.model.product_sale_code = []
            }
            this.salecode({
                uom_cat_id: this.model.uom_cat_id,
                product_id: this.model.product_id,
                product_barcode: this.model.barcode,
                product_type_id: this.model.product_type_id
            })
                .then(response => {
                    this.createSalecodeRecord(response.data)
                })
                .finally(() => {
                    this.$emit('loading', false)
                })
        },
        fetchRecipe () {
            if (this.model.product_type_id != 3) {
                this.$emit('loading', true)
                this.recipe().finally(() => {
                    this.$emit('loading', false)
                })
            } else {
                this.$store.commit('product/profile/SET_RECIPE_LIST', [])
                this.model.recipes = []
            }
        },
        createSalecodeRecord (data) {
            this.model.product_sale_code = []
            this.model.product_sale_code = Object.assign({}, data)
            this.$forceUpdate()
        },
        allowChangeUomCheck () {
            // close for now coz open when has stock in main and detail
            // this.$emit('loading', true)
            // this.allowChangUomCat(this.model.product_id)
            //     .then(response => {
            //         this.is_disabled = !response.data.status
            //     })
            //     .catch(error => {
            //         this.errors = new Errors(error.errors)
            //         this.notice({ type: 'error', text: error.message })
            //     })
            //     .finally(() => {
            //         this.$emit('loading', false)
            //     })
        },
        handleSearch: debounce(function (value) {
            this.loading = true
            this.formViewModel({
                fnName: 'account',
                search: value
            })
                .then(response => {
                    this.accounts = response.data.account
                })
                .finally(() => (this.loading = false))
        }, 800),
        notice (not) {
            this.$Notice[not.type]({
                title: 'PRODUCT',
                desc: not.text
            })
        }
    },
    watch: {
        validate: function (error) {
            this.errors = new Errors(error.errors)
        },
        'model.product_id': function (id) {
            if (id) {
                this.getSalecode()
                this.allowChangeUomCheck()
            }
        }
    }
}
</script>
