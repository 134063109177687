var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticClass:"tw-text-xs tw-uppercase tw-text-blue-700"},[_vm._v(" "+_vm._s(_vm.$t('productProfile.productSaleCode'))+" ")]),_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t('productProfile.uom')))]),_c('th',[_vm._v(_vm._s(_vm.$t('productProfile.saleCode')))]),_c('th',[_vm._v(_vm._s(_vm.$t('productProfile.cost')))]),_c('th',[_vm._v(_vm._s(_vm.$t('productProfile.quantityInHand')))]),_c('th',{staticClass:"ts-text-right"},[_vm._v(" "+_vm._s(_vm.$t('productProfile.salePrice'))+" ")])])]),_c('tbody',_vm._l((_vm.model.product_sale_code),function(code,index){return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(code.uom_name)+" ")]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(code.sale_code),expression:"code.sale_code"}],staticClass:"tw-border tw-px-2 tw-py-1 tw-rounded-md tw-outline-none",class:{
                                'tw-border-red-600': _vm.errors.has(
                                    'product_sale_code.' + index + '.sale_code'
                                )
                            },attrs:{"type":"text"},domProps:{"value":(code.sale_code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(code, "sale_code", $event.target.value)}}})]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(code.cost),expression:"code.cost"}],staticClass:"tw-border tw-px-2 tw-py-1 tw-rounded-md tw-outline-none",class:{
                                'tw-border-red-600': _vm.errors.has(
                                    'product_sale_code.' + index + '.cost'
                                )
                            },attrs:{"type":"text"},domProps:{"value":(code.cost)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(code, "cost", $event.target.value)}}})]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(code.quantity_in_hand),expression:"code.quantity_in_hand"}],staticClass:"tw-border tw-px-2 tw-py-1 tw-rounded-md tw-outline-none",class:{
                                'tw-border-red-600': _vm.errors.has(
                                    'product_sale_code.' +
                                        index +
                                        '.quantity_in_hand'
                                )
                            },attrs:{"type":"number"},domProps:{"value":(code.quantity_in_hand)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(code, "quantity_in_hand", $event.target.value)}}})]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(code.sale_price),expression:"code.sale_price"}],staticClass:"tw-border tw-px-2 tw-py-1 tw-rounded-md tw-outline-none tw-text-right tw-w-32",class:{
                                'tw-border-red-600': _vm.errors.has(
                                    'product_sale_code.' + index + '.sale_price'
                                )
                            },attrs:{"type":"number"},domProps:{"value":(code.sale_price)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(code, "sale_price", $event.target.value)}}})])])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }