<template>
    <div style="height: 31.38%">
        <h3 class="tw-text-xs tw-uppercase tw-text-blue-700">
            {{ $t('productProfile.vendorList') }}
        </h3>
        <div class="tw-space-y-2">
            <Select
                v-model="model.vendor_list"
                :filter-by-label="true"
                :placeholder="$t('select')"
                multiple
                :clearable="true"
                filterable
            >
                <Option
                    v-for="vendor in vendorList"
                    :value="vendor.vendor_id"
                    :key="vendor.vendor_id"
                >
                    {{ vendor.vendor_name }}
                </Option>
            </Select>
            <!-- <a-select
        class="ts-w-full"
        mode="multiple"
        v-model="model.vendor_list"
        :options="vendorList"
        :filterOption="(input, option) => option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0"
        :maxTagCount="4"
      ></a-select> -->
        </div>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'
import { mapState } from 'vuex'
export default {
    name: 'vendorList',
    props: ['value', 'validate', 'full'],
    data () {
        return {
            errors: new Errors(),
            model: {}
        }
    },
    created () {
        this.model = this.value
    },
    computed: {
        ...mapState('product/profile', ['formViewDatas']),
        vendorList () {
            return this.formViewDatas.supplier
        }
    },
    watch: {
        validate: function (error) {
            this.errors = new Errors(error.errors)
        }
    }
}
</script>
